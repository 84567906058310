<template>
  <div class="privacypolicy">
    <top-nav @menu_data="getMenuData" />
    <div class="privacypolicy_content">
      <div class="upper_content">
        <div class="title">Privacy Policy</div>
          <div class="user_privacy_agreement">
    <div class="title">
      Arcanite Services Pty Ltd
    </div>
    <div class="privacy_content">
        <div class="title">
          1. Introduction
        </div>
        <div class="content">
          <p>
            1.1 We respect your right to privacy and are committed to safeguarding the privacy of the personal information of our customers and users of our application. We adhere to the Australian Privacy Principles established by the <i>Privacy Act 1988</i> (Cth) (<strong>Privacy Act</strong>). This Privacy Policy (<strong>Policy</strong>) sets out how and why we collect, hold, use and disclose your personal information.
          </p>
          <p>
            1.2 In this Policy:
          </p>
          <p>
            (a)"<strong>personal information</strong>" has the same meaning given to it in the Privacy Act, which, in simple terms, means information that can be used to identify you; 
          </p>
          <p>
            (b) "<strong>application</strong>" means:
          </p>
          <p>
            (i) our website at https://www.arcanite.com.au/;
          </p>
          <p>
            (ii) our mobile phone application known as 'Arcanite' (and our other applications associated with the 'Arcanite' application); and
          </p>
          <p>
            (iii) any other website and application that we own and operate; and
          </p>
          <p>
            (c) "<strong>we</strong>", "<strong>us</strong>", and "<strong>our</strong>" means Arcanite Services Pty Ltd ABN 71 674 390 722 and its related bodies corporate.
          </p>
          <p>
            1.3 By accessing our application or using our services, you consent to the terms of this Policy, including consenting to us collecting, holding, using and disclosing your personal information in accordance with this Policy, and you agree to be bound by the Policy.
          </p>
          
        </div>
        <div class="title">
          2. What personal information we collect and hold
        </div>
        <div class="content">
          <p>
            2.1 From time to time, we may receive and collect your personal information when it is entered into by, or otherwise collected from, you or third parties when using our application. The third parties from which we may receive and collect personal information about you include:
          </p>
          <p>
            (a) our related bodies corporate;
          </p>
          <p>
            (b) your co-applicants or guarantors;
          </p>
          <p>
            (c) your representatives, including, for example, your real estate agent, mortgage broker, bank representative, solicitor or conveyancer;
          </p>
          <p>
            (d) the developers or builders of properties displayed on the application;
          </p>
          <p>
            (e) advertisers; and
          </p>
          <p>
            (f) publicly available sources of information. 
          </p>
          <p>
            2.2 When you use our application, we may ask you to provide certain personal information such as your name, phone number, address and email address.
          </p>
          <p>
            2.3 We may also collect additional information from you, which might contain your personal information, when you:
          </p>
          <p>
            (a) provide feedback; 
          </p>
          <p>
            (b) change your content or email preferences; 
          </p>
          <p>
            (c) respond to surveys or promotions; 
          </p>
          <p>
            (d) provide financial, bank account or credit card information; 
          </p>
          <p>
            (e) communicate with our customer support; or
          </p>
          <p>
            (f) otherwise contact us.
          </p>
          <p>
            2.4 We may also collect other information about you when you access and use our application, including: 
          </p>
          <p>
            (a) information regarding your interactions with our application, including:
          </p>
          <p>
            (i) when you, or persons you act on behalf, interact with a property displayed on our application;
          </p>
          <p>
            (ii) communications between you and other users of the application;
          </p>
          <p>
            (iii) properties that you, or persons you act on behalf, have purchased; and
          </p>
          <p>
            (iv) when you, or persons you act on behalf, request a contract for sale in relation to a property displayed on our application;
          </p>
          <p>
            (b) your use of our products and services;
          </p>
          <p>
            (c) the location from which you access our application; and
          </p>
          <p>
            (d) the pages you have visited.
          </p>
          <p>
            2.5 We may also collect other technical data when you access and use our application, which may include your mobile device's unique device ID, IP address, operating system, and the type of internet browser you are using. We may use this technical information in an aggregated manner to analyse how our users interact with our application, so we can improve our service and seek to provide the best possible user experience.
          </p>
          <p></p>
        </div>
        <div class="title">
          3. Why we collect, hold and use your personal information
        </div>
        <div class="content">
          <p>
            3.1 Purpose of Collection, Holding, and Use<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We collect, hold, and use your personal information to:
          </p>
          <p>
            (a) Provide our products and services to you, including email notifications and newsletters.
          </p>
          <p>
            (b) Inform you about new products, services, and opportunities as they become available.
          </p>
          <p>
            (c) Contact you to respond to your queries or complaints.
          </p>
          <p>
            (d) Improve our products and services.
          </p>
          <p>
            (e) Comply with our legal obligations.
          </p>
          <p>
            (f) Identify and inform you about other products or services that may interest you.
          </p>
          <p>
            We will use your data to provide you with the services you requested, such as email notifications and newsletters, etc.
          </p>
          <p>
            3.2 Consequences of Non-Disclosure<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you do not provide your personal information, we may be unable to:
          </p>
          <p>
            (a) Provide our services or application to you.
          </p>
          <p>
            (b) Communicate with you.
          </p>
          <p>
            (c) Respond to your queries.
          </p>
          <p>
            3.3 Use of Third-Party Social Media Features<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Our application may include third-party social media features. 
            We cannot guarantee the security of any personal information you choose to make public using these features. 
            Additionally, we cannot ensure that third parties with access to such publicly available information will respect your privacy. 
            Therefore, we disclaim responsibility for your use of these social media features.
          </p>
          <p>
            3.4 Sharing and Protection of Your Information
          </p>
          <p>
            (a) We will not sell your data to third parties, but we may share it with our partners who help us provide our services.
          </p>
          <p>
            (b) We do not transfer or disclose your information to third parties for purposes other than the ones provided.
          </p>
          <p>
            (c) Security procedures are in place to protect the confidentiality of your data.
          </p>
          <p>
            (d) We use encryption to protect your information.
          </p>
          <p>
            3.5 Data Retention
          </p>
          <p>
            (a) We store your personal information for a period of time that is consistent with our business purposes.
          </p>
          <p>
            (b) We will retain your personal information for the length of time needed to fulfill the purposes outlined in this privacy policy unless a longer retention period is required or permitted by law.
          </p>
          <p>
            (c) When the data retention period expires for a given type of data, we will delete or destroy it.
          </p>
          <p>
            (d) You may request for your data to be deleted by contacting us.
          </p>
        </div>
        <div class="title">
          4. Who we disclose your personal information to and why
        </div>
        <div class="content">
          <p>
            4.1 We may disclose your personal information to:
          </p>
          <p>
            (a) our related bodies corporate, employees, representatives and officers so we can provide our services and application to you;
          </p>
          <p>
            (b) other users of our application (for example, real estate agents and developers), so they can:
          </p>
          <p>
            (i) track interest in the properties they display on our application; and
          </p>
          <p>
            (ii) contact you to provide you with information relating to their properties; 
          </p>
          <p>
            (c) your representatives, including, for example, your real estate agent, mortgage broker, bank representative, solicitor and conveyancer, so they can provide services to you; 
          </p>
          <p>
            (d) our external service providers so they can perform services for us in connection with our application; and
          </p>
          <p>
            (e) our insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this Policy or as required by law.
          </p>
          <p>
            4.2 We may also disclose your personal information to other third parties in the following circumstances:
          </p>
          <p>
            (a) if we are required or authorised to do so by law (including under the Privacy Act) including, for example, by any court order, subpoena, warrant, during the course of any legal proceeding or in response to a law enforcement agency request; 
          </p>
          <p>
            (b) if we sell or transfer our business assets to a third party; or
          </p>
          <p>
            (c) if you have otherwise consented to the disclosure expressly or by implication.
          </p>
        </div>
        <div class="title">
          5. How we store and hold your personal information
        </div>
        <div class="content">
          <p>
            5.1 We store most personal information that we collect in computer systems and databases operated by us or our external service providers, such as third-party cloud storage providers. We may store some personal information in paper files that we store securely.
          </p>
          <p>
            5.2 We are committed to ensuring that all personal information we receive and collect is secure. To prevent unauthorised access, we have put suitable procedures in place to secure personal information, and protect it from misuse, interference, loss, unauthorised access, modification and disclosure. 
          </p>
          <p>
            5.3 These procedures include:
          </p>
          <p>
            (a) the use of identity and access management technologies to control access to systems on which information is processed and stored;
          </p>
          <p>
            (b) requiring all employees to comply with internal information security policies and keep information secure;
          </p>
          <p>
            (c) requiring all employees to complete training about information security; and
          </p>
          <p>
            (d) monitoring and regularly reviewing our practices against our own policies and against industry best practice.
          </p>
          <p>
            5.4 We will also take reasonable steps to destroy or de-identify personal information once we no longer require it for the purposes for which we collected it or for any secondary purpose, such as to fulfil any legal, accounting or reporting requirements.
          </p>
          <p>
            5.5 Personal information we collect may occasionally be stored, processed in or transferred between entities located in countries outside of Australia. These countries can include (but are not necessarily limited to) Hong Kong, Singapore, US and New Zealand.
          </p>
          <p>
            5.6 Although we take measures to safeguard against unauthorised disclosures of information, we cannot guarantee that any personal information we collect will not be used or disclosed in a manner inconsistent with this Policy.
          </p>
        </div>
        <div class="title">
          6. Use of your personal information for marketing
        </div>
        <div class="content">
          <p>
            6.1 We may use your personal information to offer you products and services we believe may interest you, but we will not do so if you tell us not to. These products and services may be offered by us, our related bodies corporate, our business partners or service providers.
          </p>
          <p>
            6.2 Where you receive electronic marketing communications from us, you may opt out of receiving further marketing communications by following the opt-out instructions provided in the communication.
          </p>
        </div>
        <div class="title">
          7. Third party links
        </div>
        <div class="content">
          <p>
            7.1 Our application may occasionally contain links to other websites or applications, which we do not own or control. These links are meant for your convenience only. Links to third party websites or applications do not constitute our sponsorship, endorsement or approval of these third parties. 
          </p>
          <p>
            7.2 We are not responsible for the privacy practices of the owners or controllers of such third party websites or applications.
          </p>
        </div>
        <div class="title">
          8. Cookies
        </div>
        <div class="content">
          <p>
            8.1 We may from time to time use cookies on our application. Cookies are very small text files which a website or application may store on your device and which contain information about your interaction with the website or application. Cookies are used to identify you when you return to the website or application, to improve your experience, by saving browsing information and your preferences, and showing content that is relevant to you. 
          </p>
          <p>
            8.2 Most devices automatically accept cookies, but you can choose to reject cookies by changing your device settings. However, this may prevent you from being able to use the full functionality of our application.
          </p>
        </div>
        <div class="title">
          9. Geo-location
        </div>
        <div class="content">
          <p>
            9.1 When you visit our application, we may use GPS technology (or other similar technology) to determine your location at the time of your visit, which allows us to display a location map and advertisements that are relevant to you. We will not share your current location with other users or partners.
          </p>
        </div>
        <div class="title">
          10. Access to and correction of your personal information
        </div>
        <div class="content">
          <p>
            10.1 You may access or request correction of personal information that we hold about you.
          </p>
          <p>
            10.2 While there is no charge to request access to your personal information, we may require you to pay an administrative fee to meet our reasonable costs to provide access to your personal information (such as photocopying costs or costs for time spent to collate large amounts of material). If you would like access to your personal information or believe that any personal information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us. Our contact details are set out in the 'Our contact details' section below. 
          </p>
          <p>
            10.3 We will respond to your requests to access or correct your personal information in a reasonable time and will take all reasonable steps to ensure that the personal information we hold about you remains accurate and up to date.
          </p>
          <p>
            10.4 We may refuse your request to access or correct your personal information if we are permitted to do so under the Privacy Act.
          </p>
        </div>
        <div class="title">
          11. Complaints about privacy
        </div>
        <div class="content">
          <p>
            11.1 If you have any complaints about our privacy practices, including your request to access or correct your personal information, please contact us. 
          </p>
          <p>
            11.2 We will consider your complaint and determine whether it requires further investigation. We will notify you of the outcome of this investigation and any subsequent internal investigation.
          </p>
          <p>
            11.3 If you remain unsatisfied with the way we have handled a privacy issue, you may approach an independent advisor or contact the Office of the Australian Information Commissioner (OAIC) (www.oaic.gov.au) for guidance on alternative courses of action which may be available.
          </p>
        </div>
        <div class="title">
          12. Our contact details
        </div>
        <div class="content">
          <p>
            12.1 If you have any questions, comments, requests or concerns, please contact us by email and/or letter at the details below:
          </p>
          <p>
            Email: 	<u>support@arcnet.com.au</u>
          </p>
          <p>
            Letter: 	Attention: Privacy Officer 
          </p>
          <p>
            66 Berry St, North Sydney NSW 2060
          </p>
        </div>
        <div class="title">
          13. Changes to our Policy
        </div>
        <div class="content">
          <p>
            13.1 We may review and update this Policy from time to time. Each updated version of this Policy will be effective immediately when it is published on our application. You may obtain a copy of our Policy from our website or by contacting us at the contact details above. Please check back from time to time to review our Policy. 
          </p>
        </div>
        <div class="content" style="marginTop:20px"><p>Date of Last Revision: Jul 15th, 2022</p>
        </div>
      </div>  
<!-- </div> -->
  </div>
      </div>
      <div class="bottom_link">
        <!-- <span>Copyright 2021 © Arcanite</span>
        <span @click="goRouter('/privacyPolicy')">Privacy Policy</span>
        <span @click="goRouter('/termsAndConditions')">Terms & Conditions</span> -->        
      </div>
    </div>
    <footer-nav/>
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false
    };
  },
  created() {},
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "menu-bar": resolve => require(["./MenuBar.vue"], resolve),
    "footer-nav": resolve => require(["./FooterNav.vue"], resolve)
  }
};
</script>
<style lang="scss" scoped>
.privacypolicy {
  .privacypolicy_content {
    background-color: #f7f7f7;
    margin-top: 103px;
    box-sizing: border-box;
    // height: calc(100vh - 103px);
    padding: 29px 25px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .upper_content {
      .title {
        text-align: left;
        font-family: Poppins;
        font-size: 24px;
        color: #000;
      }
	  
      // .content {
      //   margin-top: 20px;
      //   text-align: left;
      //   font-family: Poppins;
      //   font-size: 14px;
      //   line-height: 1.43;
      //   letter-spacing: 0.23px;
      //   color: #1e1d1e;
      // }
      .user_privacy_agreement {
        margin-top: 20px;
        text-align: left;
        font-family: Poppins;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.23px;
        color: #1e1d1e;
        // padding: 20px;
        .title {
          text-align: left;
          font-size: 14px;
          font-family: Poppins;
          font-weight: bold;
          color: rgba(55, 55, 67, 1);
          padding: 20px 0;
        }
        .content {
          font-size: 14px;
          font-family: Poppins;
          font-weight: 400;
          color: rgba(83, 84, 93, 1);
          line-height: 1.5;
          > ul {
            li {
              list-style: inside;
            }
          }
        }
      }
    }
    .bottom_link {
      font-family: Poppins;
      font-size: 12px;
      color: #9b9b9b;
      text-align: left;
      span {
        margin-right: 25px;
      }
    }
  }
}
</style>